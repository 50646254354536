<template>
  <div class='header'>
    <div class='logo'>
      <a href='/'>
        <img src='./assets/logo.svg'/>
      </a>
      
    </div>
    <div class='headerRight'>
      <ul>
        <a href='#'><li>Develop</li></a>
        <a href='#'><li>Ecosystem</li></a>
        <a href='#'><li>Resources</li></a>
        <a href='#'><li>Bridge</li></a>
      </ul>
      <div class='interact-button connectButton'>
        Connect Wallet
      </div>
    </div>
  </div>
  <div class='mainBlock'>
    <div class='mainText'>
      <p class='firstP'>The long-awaited AirDrop!</p>
      <p class='secondP'>Connect your wallet to receive cryptocurrency.<br>Scaling Ethereum for good.</p>
      <div class='interact-button connectButtonMain'>
        Connect Wallet
      </div>
    </div>
    <div class='pictureBlock'></div>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
body {
  margin: 0;
}
#app {
  font-family: 'TransSans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
}
.mainBlock {
  width:100%;

}
.mainText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40vh;
  gap: 20px;
}
.firstP {
  font-weight: 700; font-size:62px;
}
.secondP {
  font-size:24px;
}
.pictureBlock {
  background-image: url('@/assets/background.svg');
  width:100%;
  height:50vh;
  background-repeat: no-repeat;
  position: absolute;
  bottom:0;
  background-size:100%;
}
.header {
  width:100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding-left:15%;
  padding-right: 15%;
  padding-top:15px;
}
a {
  outline: none;
  text-decoration: none;
  color: black;
}
.logo {
  justify-self: left;
}
.headerRight {
  display: flex;
  align-items: center;
  gap:20px;
  justify-self: right;
}
ul {
  display: flex;
  gap:20px;
  list-style: none;
}
ul li {
  cursor: pointer;
  font-size: 18px;
  transition: 0.2s;
}
ul li:hover {
  color: #ff684b;
  font-weight: 700;
  transition: 0.2s;
}
p {
  margin: 0;
}
.connectButton {
  cursor: pointer;
  user-select: none;
  padding: 0px 1.2rem;
  border-radius: 7px;
  white-space: nowrap;
  font-family: var(--default-font-family);
  background-color: rgb(255, 104, 75);
  color: rgb(255, 248, 243);
  border: none;
  padding: 9px 20px 9px 20px;
  font-size:18px;
  font-weight: 700;
}
.connectButtonMain {
  cursor: pointer;
  user-select: none;
  padding: 0px 1.2rem;
  border-radius: 0.5rem;
  white-space: nowrap;
  font-family: var(--default-font-family);
  background-color: rgb(255, 104, 75);
  color: rgb(255, 248, 243);
  border: none;
  padding: 9px 20px 9px 20px;
  font-size:24px;
  font-weight: 700;
  width: 250px;
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
  box-sizing: border-box;
}
html {
  background-color: #fff8f3;
}
@media(max-width:938px) {
  ul {
    display: none;
  }
  .pictureBlock {
    background-size: 500%;
    background-position: -70vw;
    height: 40vh;
  }
  .firstP {
    font-size:45px;
  }
  .secondP {
    font-size: 20px;
  }
  .mainBlock {
    margin-top:50px;
  }
}
@font-face {
    font-family: 'TransSans';
    src: url('@/assets/fonts/TransSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
</style>
